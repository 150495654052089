body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}
#root {
  height: 100vh;
}

.graphiql-container .graphiql-editor-tool, .graphiql-container .graphiql-editor-tools, .graphiql-container .graphiql-toolbar {
  position: relative;
  width: 70px;
}

.loginicon {
  width: 80px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bolder;
  color: black;
  height: 40px;
  top: 10px;
  background: #2bc6ff;
  border-radius: 12px;
  cursor: pointer;
}

.urlicon {
  width: 80px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: center;
  font-weight: bolder;
  color: black;
  height: 40px;
  top: 10px;
  background: #2bc6ff;
  border-radius: 12px;
  cursor: pointer;
}

.login-form {
  display: flex;
  justify-content: center;
}

.form-box {
  margin-top: 7vh;
  background: black;
  padding-right: 80px;
  padding-left: 80px;
  border-radius: 20px;
  box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
}

.login-text {
  width: 100%;
  color: white;
  border-bottom: 2px solid white;
  line-height: 30px;
  width: 100%;
  font-weight: bolder;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  border: 1px solid lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 2px;
  padding-left: 10px;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 5px;
  font-size: 11pt;
  background: transparent;
  transition: 1s;
  outline: none;
  color: white;
}

.login-btn {
  width: 100%;
  justify-content: left;
  align-items: center;
  text-align: center;
  background-color: rgb(48, 172, 251);
  color: white;
  margin-top: 5px;
  border-radius: 20px;
  border: none;
  font-size: 15pt;
  font-weight: 700;
  transition: 1s;
  outline: none;
  cursor: pointer;
  margin-bottom: 20px;
}

.login-btn:hover {
  transform: scale(1.03);
  letter-spacing: 4px;
  background-color: #7fffd4;
  color: #1e90ff;
}

.show {
  display: block;
  transition: 800ms;
  opacity: 1;
}

.show.active {
  transform: translateY(100px) scale(0) rotateY(180deg);
  opacity: 0;
}
